var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "variantFrom",
          attrs: { model: _vm.formListQuery, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("common.PromotionName"),
                prop: "title",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticClass: "input-width",
                attrs: {
                  "show-word-limit": "",
                  maxlength: "50",
                  placeholder: _vm.$t("common.Pleaseentercontent")
                },
                on: { input: _vm.changeCheckList },
                model: {
                  value: _vm.formListQuery.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "title", $$v)
                  },
                  expression: "formListQuery.title"
                }
              })
            ],
            1
          ),
          _c("el-form-item", {
            attrs: {
              label: _vm.$t("common.Selectproduct"),
              "label-width": _vm.formLabelWidth
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success" },
                        on: { click: _vm.changeGoods }
                      },
                      [_vm._v(_vm._s(_vm.$t("common.Selectproduct")))]
                    )
                  ]
                }
              }
            ])
          }),
          _vm.formListQuery.pageDataSelectList &&
          _vm.formListQuery.pageDataSelectList.length > 0
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": _vm.formLabelWidth } },
                    [
                      _c(
                        "el-table",
                        {
                          ref: "pageDataSelect",
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.formListQuery.pageDataSelectList,
                            border: ""
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsId",
                              label: _vm.$t("common.ProductID"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsCode",
                              label: _vm.$t("common.SKU_EAN_Encoding"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("span", [
                                        _vm._v("SKU:" + _vm._s(scope.row.sku))
                                      ]),
                                      _c("br"),
                                      _c("span", [
                                        _vm._v(
                                          "EAN:" + _vm._s(scope.row.barCode)
                                        )
                                      ]),
                                      _c("br"),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("common.coding")) +
                                            ":" +
                                            _vm._s(scope.row.goodsCode)
                                        )
                                      ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              3410286336
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "imageUrl",
                              label: _vm.$t("common.Productimage1"),
                              align: "center"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("listImage", {
                                        attrs: { imgUrl: scope.row.imageUrl }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1058851240
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "title",
                              label: _vm.$t("common.ProductName"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "goodsSpecials",
                              label: _vm.$t("common.Countrieslisted"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return _vm._l(
                                      scope.row.goodsSpecials,
                                      function(s, index) {
                                        return _c(
                                          "span",
                                          { key: s.countryCode },
                                          [
                                            s.shelf == true
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$dict.getDictValue(
                                                          "countrys",
                                                          s.countryCode
                                                        )
                                                      )
                                                  ),
                                                  _c("br")
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      }
                                    )
                                  }
                                }
                              ],
                              null,
                              false,
                              756755268
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "prices",
                              label: _vm.$t("common.inventory"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return _vm._l(
                                      scope.row.goodsSpecials,
                                      function(s, index) {
                                        return _c("span", { key: index }, [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(s.countryCode) +
                                              ":" +
                                              _vm._s(s.ecomRemainQty)
                                          ),
                                          _c("br")
                                        ])
                                      }
                                    )
                                  }
                                }
                              ],
                              null,
                              false,
                              590014020
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "packageQuantity",
                              label: _vm.$t("common.PackingQuantity"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "prices",
                              label: _vm.$t("common.Fixedprice1"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return _vm._l(scope.row.prices, function(
                                      s,
                                      index
                                    ) {
                                      return _c("span", { key: index }, [
                                        s.fixedPrice
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(s.countryCode) +
                                                  ":" +
                                                  _vm._s(
                                                    Number(
                                                      s.fixedPrice
                                                    ).toFixed(2)
                                                  ) +
                                                  _vm._s(
                                                    _vm.$dict.getDictCurrencyValue(
                                                      s.countryCode
                                                    )
                                                  )
                                              ),
                                              _c("br")
                                            ])
                                          : _vm._e()
                                      ])
                                    })
                                  }
                                }
                              ],
                              null,
                              false,
                              2945861441
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "prices",
                              label: _vm.$t("common.Lowestprice"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return _vm._l(scope.row.prices, function(
                                      s,
                                      index
                                    ) {
                                      return _c("span", { key: index }, [
                                        s.lowestPrice
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(s.countryCode) +
                                                  ":" +
                                                  _vm._s(
                                                    Number(
                                                      s.lowestPrice
                                                    ).toFixed(2)
                                                  ) +
                                                  _vm._s(
                                                    _vm.$dict.getDictCurrencyValue(
                                                      s.countryCode
                                                    )
                                                  )
                                              ),
                                              _c("br")
                                            ])
                                          : _vm._e()
                                      ])
                                    })
                                  }
                                }
                              ],
                              null,
                              false,
                              3335674497
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "brand",
                              label: _vm.$t("common.fixedprice"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return _vm._l(scope.row.prices, function(
                                      s,
                                      index
                                    ) {
                                      return _c("span", { key: index }, [
                                        s.resultPrice
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(s.countryCode) +
                                                  ":" +
                                                  _vm._s(
                                                    s.resultPrice.toFixed(2)
                                                  ) +
                                                  _vm._s(
                                                    _vm.$dict.getDictCurrencyValue(
                                                      s.countryCode
                                                    )
                                                  )
                                              ),
                                              _c("br")
                                            ])
                                          : _vm._e()
                                      ])
                                    })
                                  }
                                }
                              ],
                              null,
                              false,
                              881677955
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "brand",
                              label: _vm.$t("common.price1"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return _vm._l(scope.row.prices, function(
                                      s,
                                      index
                                    ) {
                                      return _c("span", { key: index }, [
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  s.salePriceEbp ||
                                                  s.salePriceEbp >= 0,
                                                expression:
                                                  "s.salePriceEbp || s.salePriceEbp>=0"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(s.countryCode) +
                                                ":" +
                                                _vm._s(
                                                  s.salePriceEbp.toFixed(2)
                                                ) +
                                                _vm._s(
                                                  _vm.$dict.getDictCurrencyValue(
                                                    s.countryCode
                                                  )
                                                )
                                            ),
                                            _c("br")
                                          ]
                                        )
                                      ])
                                    })
                                  }
                                }
                              ],
                              null,
                              false,
                              3124635391
                            )
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "shelf",
                              label: _vm.$t("common.state"),
                              align: "center",
                              "show-overflow-tooltip": ""
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      scope.row.shelf == true
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: { color: "#46a8a0" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("common.Listed"))
                                              )
                                            ]
                                          )
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("common.Notlisted"))
                                            )
                                          ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1434026108
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-tabs",
                    { on: { "tab-click": _vm.changeUserPromotionTabs } },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: _vm.$t("common.NewUserPromotion") } },
                        [
                          _c("user-promotion-dialog", {
                            ref: "newUserPromotion",
                            attrs: {
                              "is-edit": _vm.isEdit,
                              "price-setting": _vm.priceSetting,
                              "form-data": _vm.newUserFormData
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            label: _vm.$t("common.RegularUserPromotion")
                          }
                        },
                        [
                          _c("user-promotion-dialog", {
                            ref: "oldUserPromotion",
                            attrs: {
                              "is-edit": _vm.isEdit,
                              "price-setting": _vm.priceSetting,
                              "form-data": _vm.oldUserFormData
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { staticStyle: { width: "20%" }, on: { click: _vm.closeDialog } },
            [_vm._v(_vm._s(_vm.$t("common.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.submitBefore("variantFrom")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("common.confirm1")))]
          )
        ],
        1
      ),
      _vm.dialogFormTableVisible2
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("common.Selectproduct"),
                visible: _vm.dialogFormTableVisible2,
                "append-to-body": ""
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormTableVisible2 = $event
                },
                close: _vm.closeDialogGoods
              }
            },
            [
              _c(
                "div",
                [
                  _c("FormTableDialog", {
                    attrs: {
                      argFrom: _vm.argFrom2,
                      dictionary: _vm.dictionary,
                      placeholderFrom: _vm.placeholderFrom2,
                      pageDataSelectList: _vm.formListQuery.pageDataSelectList
                    },
                    on: {
                      handleSearchList: _vm.handleSearchList2,
                      handleResetSearch: _vm.handleResetSearch2,
                      handleSelected: _vm.handleSelected
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "tableSlot",
                          fn: function() {
                            return [
                              _c(
                                "el-table",
                                {
                                  ref: "tableData",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: _vm.pageData2.list,
                                    "tree-props": {
                                      children: "packageQtyList",
                                      hasChildren: "hasChildren"
                                    },
                                    id: "goodsTable",
                                    border: "",
                                    "row-key": "goodsId",
                                    "header-cell-style": {
                                      background: "#EAEEF9",
                                      color: "#32353a"
                                    },
                                    "default-expand-all": ""
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      width: "55",
                                      align: "center",
                                      type: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "seRadio" },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    {
                                                      attrs: {
                                                        label:
                                                          scope.row.goodsId,
                                                        disabled: !scope.row
                                                          .checkboxStatus
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.selectionChange(
                                                            scope.row
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value: _vm.selectionId,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.selectionId = $$v
                                                        },
                                                        expression:
                                                          "selectionId"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s("") +
                                                          "\n                                        "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      4216621667
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "quantityType",
                                      label: "",
                                      width: "50px",
                                      align: "center"
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goodsId",
                                      label: _vm.$t("common.ProductID"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goodsCode",
                                      label: _vm.$t("common.SKU_EAN_Encoding"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  "SKU:" + _vm._s(scope.row.sku)
                                                )
                                              ]),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  "EAN:" +
                                                    _vm._s(scope.row.barCode)
                                                )
                                              ]),
                                              _c("br"),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("common.coding")
                                                  ) +
                                                    ":" +
                                                    _vm._s(scope.row.goodsCode)
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3410286336
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "imageUrl",
                                      label: _vm.$t("common.Productimage1"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.imageUrl
                                                ? _c("el-image", {
                                                    staticStyle: {
                                                      height: "100px"
                                                    },
                                                    attrs: {
                                                      src:
                                                        _vm.ossHost +
                                                        scope.row.imageUrl,
                                                      fit: "fill"
                                                    }
                                                  })
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      183490098
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "packageQuantity",
                                      label: _vm.$t("common.PackingQuantity"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "title",
                                      label: _vm.$t("common.ProductName"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    }
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "goodsSpecials",
                                      label: _vm.$t("common.Countrieslisted"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return _vm._l(
                                              scope.row.goodsSpecials,
                                              function(s, index) {
                                                return _c(
                                                  "span",
                                                  { key: index },
                                                  [
                                                    s.shelf
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$dict.getDictValue(
                                                                "countrys",
                                                                s.countryCode
                                                              )
                                                            )
                                                          ),
                                                          _c("br")
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                )
                                              }
                                            )
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2013868671
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "prices",
                                      label: _vm.$t("common.Promotionprice1"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm.isShowGoodsPromotion(
                                                scope.row.promotionRuleCountrys,
                                                "NEW"
                                              )
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold",
                                                            "text-align":
                                                              "left",
                                                            display: "block"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.NewUserPromotion"
                                                              )
                                                            )
                                                          ),
                                                          _c("br")
                                                        ]
                                                      ),
                                                      _vm._l(
                                                        scope.row
                                                          .promotionRuleCountrys,
                                                        function(
                                                          promotion,
                                                          index
                                                        ) {
                                                          return promotion.userType ==
                                                            "NEW"
                                                            ? _c(
                                                                "span",
                                                                { key: index },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                            " +
                                                                      _vm._s(
                                                                        _vm.$dict.getDictValue(
                                                                          "countrys",
                                                                          promotion.countryCode
                                                                        )
                                                                      ) +
                                                                      ":" +
                                                                      _vm._s(
                                                                        _vm.getPromotionGoodsPrice(
                                                                          promotion,
                                                                          scope
                                                                            .row
                                                                            .prices
                                                                        )
                                                                      )
                                                                  ),
                                                                  _c("br")
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              _vm.isShowGoodsPromotion(
                                                scope.row.promotionRuleCountrys,
                                                "OLD"
                                              )
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold",
                                                            "text-align":
                                                              "left",
                                                            display: "block"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "common.RegularUserPromotion"
                                                              )
                                                            )
                                                          ),
                                                          _c("br")
                                                        ]
                                                      ),
                                                      _vm._l(
                                                        scope.row
                                                          .promotionRuleCountrys,
                                                        function(
                                                          promotion,
                                                          index
                                                        ) {
                                                          return promotion.userType ==
                                                            "OLD"
                                                            ? _c(
                                                                "span",
                                                                { key: index },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                            " +
                                                                      _vm._s(
                                                                        _vm.$dict.getDictValue(
                                                                          "countrys",
                                                                          promotion.countryCode
                                                                        )
                                                                      ) +
                                                                      ":" +
                                                                      _vm._s(
                                                                        _vm.getPromotionGoodsPrice(
                                                                          promotion,
                                                                          scope
                                                                            .row
                                                                            .prices
                                                                        )
                                                                      )
                                                                  ),
                                                                  _c("br")
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  )
                                                : _vm._e(),
                                              !scope.row
                                                .promotionRuleCountrys ||
                                              scope.row.promotionRuleCountrys
                                                .length == 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$i18n.t(
                                                          "common.ThereAreCurrentlyNoProductPromotions"
                                                        )
                                                      )
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      119494656
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "prices",
                                      label: _vm.$t("common.fixedprice"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return _vm._l(
                                              scope.row.prices,
                                              function(s, index) {
                                                return _c(
                                                  "span",
                                                  { key: index },
                                                  [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          _vm.$dict.getDictValue(
                                                            "countrys",
                                                            s.countryCode
                                                          )
                                                        ) +
                                                        ":"
                                                    ),
                                                    s.resultPrice
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              s.resultPrice.toFixed(
                                                                2
                                                              )
                                                            ) +
                                                              _vm._s(
                                                                _vm.$dict.getDictCurrencyValue(
                                                                  s.countryCode
                                                                )
                                                              )
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    _c("br")
                                                  ]
                                                )
                                              }
                                            )
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3281174452
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "prices",
                                      label: _vm.$t("common.Fixedprice1"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return _vm._l(
                                              scope.row.prices,
                                              function(s, index) {
                                                return _c(
                                                  "span",
                                                  { key: index },
                                                  [
                                                    s.fixedPrice
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              s.countryCode
                                                            ) +
                                                              ":" +
                                                              _vm._s(
                                                                Number(
                                                                  s.fixedPrice
                                                                ).toFixed(2)
                                                              ) +
                                                              _vm._s(
                                                                _vm.$dict.getDictCurrencyValue(
                                                                  s.countryCode
                                                                )
                                                              )
                                                          ),
                                                          _c("br")
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                )
                                              }
                                            )
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2945861441
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "prices",
                                      label: _vm.$t("common.Lowestprice"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return _vm._l(
                                              scope.row.prices,
                                              function(s, index) {
                                                return _c(
                                                  "span",
                                                  { key: index },
                                                  [
                                                    s.lowestPrice
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              s.countryCode
                                                            ) +
                                                              ":" +
                                                              _vm._s(
                                                                Number(
                                                                  s.lowestPrice
                                                                ).toFixed(2)
                                                              ) +
                                                              _vm._s(
                                                                _vm.$dict.getDictCurrencyValue(
                                                                  s.countryCode
                                                                )
                                                              )
                                                          ),
                                                          _c("br")
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                )
                                              }
                                            )
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      3335674497
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "shelf",
                                      label: _vm.$t("common.state"),
                                      align: "center",
                                      "show-overflow-tooltip": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              scope.row.shelf == true
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#46a8a0"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "common.Listed"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "common.Notlisted"
                                                        )
                                                      )
                                                    )
                                                  ])
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1434026108
                                    )
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "block" },
                                [
                                  _c("el-pagination", {
                                    attrs: {
                                      "current-page": _vm.listQuery.page,
                                      "page-size": _vm.listQuery.size,
                                      layout:
                                        "total, prev, pager, next, jumper",
                                      total: _vm.pageData2.total
                                    },
                                    on: {
                                      "current-change": _vm.handleCurrentChange
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      4279785222
                    )
                  })
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.SubmitConfirmation"),
            visible: _vm.dialogGetPricingVisible,
            width: "30%",
            "append-to-body": "",
            "before-close": _vm.handleGetPricingClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogGetPricingVisible = $event
            }
          }
        },
        [
          _vm.dialogGetPricingVisible
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "80%" } },
                      _vm._l(_vm.getPricing, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticStyle: { margin: "10px 10px" } },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$dict.getDictValue(
                                    "countrys",
                                    item.countryCode
                                  )
                                ) +
                                "（" +
                                _vm._s(
                                  item.userType == "NEW"
                                    ? _vm.$t("common.NewUserPromotion")
                                    : _vm.$t("common.RegularUserPromotion")
                                ) +
                                "）" +
                                _vm._s(
                                  _vm.$t(
                                    "common.TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIs"
                                  )
                                )
                            ),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(item.profit.toFixed(2)) + "%")
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.dialogGetPricingVisible = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("common.Cancel")))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", loading: _vm.loading },
                        on: {
                          click: function($event) {
                            return _vm.finallySubmit(_vm.isEdit)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("common.Confirm")))]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.Prompt"),
            visible: _vm.dialogLowestPriceVisible,
            width: "20%",
            "append-to-body": "",
            "before-close": _vm.handleLowestPriceClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogLowestPriceVisible = $event
            }
          }
        },
        [
          _vm.dialogLowestPriceVisible
            ? _c("div", [
                _c("h4", [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "common.ThePromotionalPriceCannotBeLowerThanTheLowestPrice"
                        )
                      ) + "："
                    )
                  ])
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "80%" } },
                      _vm._l(_vm.checkLowestPrice, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticStyle: { margin: "10px 10px" } },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(
                                  _vm.$dict.getDictValue(
                                    "countrys",
                                    item.countryCode
                                  )
                                ) +
                                "（" +
                                _vm._s(
                                  item.userType == "NEW"
                                    ? _vm.$t("common.NewUserPromotion")
                                    : _vm.$t("common.RegularUserPromotion")
                                ) +
                                "）:"
                            ),
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(item.price.toFixed(2)))
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.dialogLowestPriceVisible = false
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("common.Confirm")))]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      ),
      _c("TimeOverlayDialog", {
        attrs: {
          "dialog-time-overlay-visible": _vm.dialogTimeOverlayVisible,
          "check-time-overlay": _vm.checkTimeOverlay
        },
        on: { dialogVisibleEvent: _vm.handleTimeOverlayClose }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }