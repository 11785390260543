<template>
    <div v-loading="formLoading">
        <el-form>
            <el-form-item :label="$i18n.t('common.Selectproduct')" :label-width="formLabelWidth">
                <div>
                    <el-button type="success" @click="changeGoods">{{$i18n.t("common.Pleaseselectaproduct")}}</el-button>
                    <el-button class="umar-b10  upload-btn" type="primary">{{$i18n.t("common.ImportFile")}}
                        <div id="clone_input">
                            <input class="upload-btn-file" type="file" ref="file" @change="importClick" />
                        </div>
                    </el-button>
                    <el-button
                        @click="downloadImportTpl()"
                        plain
                    >{{$t('common.DownloadImportTemplate')}}</el-button>
                </div>
            </el-form-item>
        </el-form>
        <div v-show="tableList && tableList.length > 0">
            <el-form>
                <el-form-item :label-width="formLabelWidth">
                    <el-table default-expand-all ref="pageDataSelect" :data="tableList" :tree-props="{children: 'packageQtyList', hasChildren: 'hasChildren'}" border style="width: 100%" :cell-style="productIdCellStyle">
                        <!-- 用于显示子表格张开按钮 -->
                        <el-table-column prop="quantityType" label="" width="50px" align="center">
                        </el-table-column>
                        <el-table-column prop="goodsId" :label="$t('common.ProductID')" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="goodsCode" :label="$t('common.SKU_EAN_Encoding')" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span>SKU:{{ scope.row.sku }}</span><br />
                                <span>EAN:{{ scope.row.barCode }}</span><br />
                                <span>{{$i18n.t("common.coding")}}:{{ scope.row.goodsCode }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="imageUrl"   :label='$i18n.t("common.Productimage1")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <el-image style="height: 100px" v-if="scope.row.imageUrl"
                                    :src="ossHost + scope.row.imageUrl" fit="fill"></el-image>
                            </template>
                        </el-table-column>

                        <el-table-column prop="packageQuantity" :label="$t('common.PackingQuantity')" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="title"  :label='$i18n.t("common.ProductName")' align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="goodsSpecials" :label='$i18n.t("common.Countrieslisted")'  align="center" show-overflow-tooltip>

                            <template slot-scope="scope">
                                <span v-for="s in scope.row.goodsSpecials" :key="s.countryCode">
                                    <span v-if="s.shelf == true"> {{
                                        $dict.getDictValue('countrys', s.countryCode)
                                    }}<br />
                                    </span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="prices" :label='$i18n.t("common.inventory")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                                    {{ s.countryCode }}:{{ s.ecomRemainQty }}<br />
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="brand" :label='$i18n.t("common.fixedprice")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.prices" :key="index">
                                    <span v-if="s.resultPrice">{{ s.countryCode }}:{{
                                        s.resultPrice.toFixed(2)
                                    }}{{$dict.getDictCurrencyValue(s.countryCode)}}<br /></span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="brand" :label='$i18n.t("common.Fixedprice1")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.prices" :key="index">
                                    <span v-if="s.fixedPrice">{{ s.countryCode }}:{{
                                        Number(s.fixedPrice).toFixed(2)
                                    }}{{$dict.getDictCurrencyValue(s.countryCode)}}<br /></span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="prices" :label='$i18n.t("common.Lowestprice")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.prices" :key="index">
                                    <span v-if="s.lowestPrice">{{ s.countryCode }}:{{
                                        Number(s.lowestPrice).toFixed(2)
                                        }}{{$dict.getDictCurrencyValue(s.countryCode)}}<br /></span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="shelf" :label='$i18n.t("common.state")'  align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-if="scope.row.shelf == true" style="color: #46a8a0">{{$i18n.t("common.Listed")}}</span>
                                <span v-else>{{$i18n.t("common.Notlisted")}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label='$i18n.t("common.operation")'  fixed="right" width="150" align="center">
                            <template slot-scope="scope">
                                <el-button @click="setup(scope.row, scope.$index)" type="text" size="small">{{$i18n.t("common.Setuppromotions")}}</el-button>
                                <el-button @click="del(scope.row)" type="text" size="small">{{$i18n.t("common.delete")}}</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
        </div>

        <el-dialog  :title='$i18n.t("common.Selectproduct")' v-if="dialogFormTableVisible" :visible.sync="dialogFormTableVisible" @close="closeDialogGoods" append-to-body>

            <FormTableDialog :argFrom="argFrom" :dictionary="dictionary" :pageDataSelectList="pageDataSelectList"
                :placeholderFrom="placeholderFrom" @handleResetSearch="handleResetSearch"
                @handleChangeCategory="handleChangeCategory" @handleSelected="handleSelected"
                @handleSearchList="handleSearchList">
                <template v-slot:tableSlot>

                    <h3 style="color:red;margin:10px 0px;">{{$i18n.t("common.Selectupto50")}}</h3>
                    <el-table ref="tableData" :data="pageData.list" style="width: 100%" height="500" border row-key="goodsId" default-expand-all

                        :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }"
                        :tree-props="{children: 'packageQtyList', hasChildren: 'hasChildren'}"
                        id="goodsTable"
                        @select="selectOfSelectable"
                        @select-all="selectAllOfSelectable"
                        @selection-change="handleSelectionChange">
                        <el-table-column align="center" :reserve-selection="true" type="selection" width="55" :selectable="row => row.checkboxStatus" >
                        </el-table-column>
                        <!-- 用于显示子表格张开按钮 -->
                        <el-table-column prop="quantityType" label="" width="50px" align="center">
                        </el-table-column>
                        <el-table-column prop="goodsId" :label='$i18n.t("common.ProductID")' align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="goodsCode" :label="$t('common.SKU_EAN_Encoding')" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span>SKU:{{ scope.row.sku }}</span><br />
                                <span>EAN:{{ scope.row.barCode }}</span><br />
                                <span>{{$i18n.t("common.coding")}}:{{ scope.row.goodsCode }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="imageUrl"  :label='$i18n.t("common.Productimage1")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <el-image style="height: 100px" v-if="scope.row.imageUrl"
                                    :src="ossHost + scope.row.imageUrl" fit="fill"></el-image>
                            </template>
                        </el-table-column>

                        <el-table-column prop="packageQuantity" :label="$t('common.PackingQuantity')" align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="title"  :label='$i18n.t("common.ProductName")' align="center" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="goodsSpecials" :label='$i18n.t("common.Countrieslisted")' align="center" show-overflow-tooltip>

                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                                    <span v-if="s.shelf">{{
                                        $dict.getDictValue('countrys', s.countryCode)
                                    }}<br /></span>
                                </span>
                            </template>
                        </el-table-column>

<!--                        <el-table-column prop="endTime" label="促销价" align="center" show-overflow-tooltip>

                            <template slot-scope="scope" v-if="scope.row.prices.length > 0">
                                <span v-for="(s, index) in scope.row.prices" :key="index">
                                    {{ $dict.getDictValue('countrys', s.countryCode) }}:<span v-if="s.promotionPrice">{{
                                        s.promotionPrice.toFixed(2)
                                    }}</span><br />
                                </span>
                            </template>
                        </el-table-column>-->
                        <el-table-column prop="prices" :label="$t('common.Promotionprice1')" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                    <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'NEW')">
                                        <span style="font-weight:bold;text-align: left;display: block;">{{$t('common.NewUserPromotion')}}<br/></span>
                                        <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'NEW'">
                                            {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                                        </span>
                                    </span>
                                <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'OLD')">
                                        <span style="font-weight:bold;text-align: left;display: block;">{{$t('common.RegularUserPromotion')}}<br/></span>
                                        <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'OLD'">
                                            {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                                        </span>
                                    </span>
                                <span v-if="!scope.row.promotionRuleCountrys || scope.row.promotionRuleCountrys.length == 0">{{ $i18n.t("common.ThereAreCurrentlyNoProductPromotions") }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="prices" :label="$t('common.inventory')" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                                    {{ s.countryCode }}:{{ s.ecomRemainQty }}<br/>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="prices" :label='$i18n.t("common.fixedprice")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.prices" :key="index">
                                    {{ $dict.getDictValue('countrys', s.countryCode) }}:<span v-if="s.resultPrice">{{
                                        s.resultPrice.toFixed(2)
                                    }}</span><br />
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="brand" :label='$i18n.t("common.price1")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.prices" :key="index">
                                    <span v-show="s.salePriceEbp || s.salePriceEbp>=0">{{ s.countryCode }}:{{
                                        s.salePriceEbp.toFixed(2)
                                    }}<br /></span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="prices" :label='$i18n.t("common.Fixedprice1")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.prices" :key="index">
                                    <span v-if="s.resultPrice">{{ s.countryCode }}:{{
                                        Number(s.fixedPrice).toFixed(2)
                                    }}<br /></span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="prices" :label='$i18n.t("common.Lowestprice")'  align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.prices" :key="index">
                                    <span v-if="s.lowestPrice">{{ s.countryCode }}:{{
                                        Number(s.lowestPrice).toFixed(2)
                                        }}{{$dict.getDictCurrencyValue(s.countryCode)}}<br /></span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="shelf" :label='$i18n.t("common.state")' align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-if="scope.row.shelf == true" style="color: #46a8a0">{{$i18n.t("common.Listed")}}</span>
                                <span v-else>{{$i18n.t("common.Notlisted")}}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="block">
                        <el-pagination @current-change="handleCurrentChange" :current-page="listQuery.page"
                            :page-size="listQuery.size" layout="total, prev, pager, next, jumper" :total="pageData.total">
                        </el-pagination>
                    </div>
                </template>
            </FormTableDialog>
        </el-dialog>
        <span slot="footer" class="dialog-footer">
            <el-button style="width: 20%;" @click="closeAddDialog"> {{ $i18n.t("common.cancel") }}</el-button>
            <el-button style="width: 20%;" type="primary" :loading="loading" @click="onSubmit"> {{ $i18n.t("common.confirm") }}</el-button>
        </span>

        <TimeOverlayDialog :dialog-time-overlay-visible='dialogTimeOverlayVisible' :check-time-overlay='checkTimeOverlay'
                           @dialogVisibleEvent='handleTimeOverlayClose'></TimeOverlayDialog>
</div>
</template>
<script>
import {selectAllOfSelectable, selectOfSelectable, showSelectOfSelectable} from "@/utils/treeTable";
import dayUtils from '@/utils/dayUtils';
import { read, utils } from 'xlsx'
import FilterContainer from '@/components/common/FilterContainer.vue';
import FormTableDialog from '@/components/common/FormTableDialog.vue';
import {
    getComputePrice,
    getPromotionGoodsPrice,
    handleOrderPurchaseLimit,
    isShowGoodsPromotion
} from '@/utils/promotion';
import { toIntOrDefault, toFloatOrDefault }from '@/utils/numberUtil';
import TimeOverlayDialog from '@/views/promotion/components/TimeOverlayDialog.vue';
export default {
    components: {
        TimeOverlayDialog,
        FilterContainer,
        FormTableDialog
    },
    props: {
        dictionary: {
            type: Object,
            default: () => {
                return {};
            }
        },
        formListQuery: {
            type: Object,
            default: () => {
                return {}
            }
        },
        changeSelectObj: {
            type: Object,
            default: () => {
                return {}
            }
        },
        tableList: {
            type: Array,
            default: () => {
                return []
            }
        },
        preSaleCountryFlag: {
            type: Boolean,
            default: () => {
                return null
            }
        }
    },
    data() {
        return {
            dialogTimeOverlayVisible: false,//促销时间重叠检验弹窗
            checkTimeOverlay: [],
            formLoading:false,
            loading: false,
            pageData: {
                list: []
            },
            //选中的类目
            category: '',
            pageDataSelectList: [],//选中的列表数据
            ossHost: process.env.VUE_APP_OSS_Head,
            listQuery: {
                countryCode: '',
                classifyId: '',
                idFuzzy: '',
                brandCode: '',
                updateUserId: '', //操作人
                inPromotion: '', //是否促销
                isOnShelf: '', //是否上架
                beginCreateTime: '',
                endCreateTime: '',
                beginUpdateTime: '',
                endUpdateTime: '',
                preSaleCountryFlag: '',
                page: 1,
                size: 10
            },
            formLabelWidth: '150px',
            dialogFormTableVisible: false,
            //组件标签是否显示
            argFrom: {
                name: true,
                category: true,
                useState: true
            },
            placeholderFrom: {
                name: this.$i18n.t("common.SKU_EAN_Encoding"),
                receiveState: this.$i18n.t("common.state")
            },
            flag: false,
            promotionImportTplUrl: process.env.VUE_APP_OSS_Head + "b2c/tpl/aigo_promotion_activity_tpl.xlsx",
        }
    },
    methods: {
        // 产品ID列单元格背景颜色处理
        productIdCellStyle({ row, column, rowIndex, columnIndex}) {
            // 未校验通过设置为红色，通过则是白色
            if(column.property === "goodsId"){
                if (row.formListQuery && row.formListQuery.promotionCheckResult){
                    return "backgroundColor:white"
                }else{
                    return "backgroundColor:red"
                }
            }
            return "backgroundColor:white"
        },
        selectAllOfSelectable(selection) {
            selectAllOfSelectable(selection, this.$refs.tableData, this.pageData.list)
        },
        selectOfSelectable(selection) {
            selectOfSelectable(selection, this.$refs.tableData, this.pageData.list)
        },
        closeAddDialog() {
            this.$emit('closeAddDialog');
        },
        closeDialogGoods() {
            this.$refs.tableData.clearSelection();
            this.dialogFormTableVisible = false
        },
        handleTimeOverlayClose(visible) {
            this.dialogTimeOverlayVisible = visible;
        },
        //选择商品
        changeGoods() {
            //加个锁防止重复触发selection-change事件
            this.listQuery.page=1
            this.dialogFormTableVisible = true;
            this.$nextTick(()=>{
                if(this.tableList && this.tableList.length>0){
                    this.tableList.forEach(item => {
                        this.$refs.tableData.toggleRowSelection(item,true)
                    });
                    this.pageDataSelectList = this.tableList
                    this.pageDataSelectList = this.pageDataSelectList.map((item) => {
                        return item
                    })
                    this.handleSearchList();


                }

            })
        },
        handleSearchList(listQuery,type) {
            this.listQuery = {
                countryCode: '',
                classifyId: "",
                idFuzzy: '',
                brandCode: '',
                updateUserId: '', //操作人
                inPromotion: '', //是否促销
                effectShelf: '', //是否上架
                beginCreateTime: '',
                endCreateTime: '',
                beginUpdateTime: '',
                endUpdateTime: '',
                preSaleCountryFlag: '',
                page: this.listQuery.page,
                size: this.listQuery.size
            };
            if (listQuery) {
                if(type!=1){
					this.listQuery.page=1
				}	
                this.listQuery.classifyId = this.category ? this.category : ""
                this.listQuery.idFuzzy = listQuery.title
                this.listQuery.effectShelf = listQuery.isUsed
            }
            if(this.preSaleCountryFlag !==null){
                this.listQuery.preSaleCountryFlag = this.preSaleCountryFlag
            }

            let temp = JSON.stringify(this.listQuery, (key, value) => {
                if (value !== null && value !== '') {
                    return value;
                }
            });
            this.listQuery = JSON.parse(temp);
            this.$store.dispatch('postGoodsSearch', this.listQuery).then((res) => {
                this.pageData = res;

                if (this.$refs.tableData) {
                    showSelectOfSelectable(this.$refs.tableData, this.pageData.list)
                }

            });
        },
        //选中商品事件
        handleSelectionChange(val) {
            if (this.flag) return;
            let temp = {}; //存放id
            let result = []; //新数组           
            if (val.length > 50) {
                // 截取50位后,不需要的数据
                let tempArr = val.slice(50);
                tempArr.forEach((item) => {
                    this.$refs.tableData.toggleRowSelection(item, false);
                });
                // 截取前50位
                val = val.slice(0, 50);
            }
            val.map((item, index) => {
                if (!temp[item.goodsId]) {
                    result.push(item);
                    temp[item.goodsId] = true;
                }
            })
            this.pageDataSelectList = result.map((item) => {
                return item
            })
        },
        //下载导入模版
        downloadImportTpl() {
            // window.open(this.promotionImportTplUrl,"_blank");

            let fileName= 'aigo_promotion_activity_tpl.xlsx';
            this.$store.dispatch('downloadTemplatePromotion', { fileName: fileName }).then((res) => {
                this.$message({
                    type: 'success',
                    message: this.$i18n.t("common.Downloadsuccessful")
                });
            });

        },
        //导入
        importClick() {
            let file = this.$refs.file.files[0];
            let ext = this.getSuffix(file.name);
            let accept = ['.xls', '.xlsx']
            if (accept.indexOf(ext) < 0) {
                this.$message.error(this.$i18n.t("common.OnlySupportsFilesInFormat",{xxx:accept}));
                return;
            }
            const fileReader = new FileReader();
            // 校验导入促销信息的合法性
            function validatePromotion(promotionRow, goodsSpecialMap, priceMap) {
                // 校验促销名称 现在只校验非空
                if(promotionRow.title === undefined || promotionRow.title === null || promotionRow.title === ''){
                    return false;
                }
                // SKU是接口查询的，返回给table的都是有SKU的，不校验

                //促销国家:导入数据的国家编码和商品上架国家比较，前面导入的时候已经校验了
                for(const prc of promotionRow.promotionRuleCountrys){
                    //数值转换 默认为0
                    prc.virtualQty = toIntOrDefault(prc.virtualQty)
                    prc.promotionCount = toIntOrDefault(prc.promotionCount)
                    prc.sort = toIntOrDefault(prc.sort)
                    //prc.userRestrictions = toIntOrDefault(prc.userRestrictions)
                   // prc.orderRestrictions = toIntOrDefault(prc.orderRestrictions)
                    // 需要根据促销类型 进行校验
                    prc.promotionValue = toFloatOrDefault(prc.promotionValue)
                    prc.promotionValue = prc.promotionValue.toFixed(2)
                    // 校验促销的开始和结束时间:  1、不能为空 2、开始时间小于结束时间
                    if(isNaN(prc.beginTime) || isNaN(prc.endTime) || prc.beginTime >= prc.endTime){
                        prc.beginTime = null
                        prc.endTime = null
                        prc.time = []
                    }
                }
                for(let prc of promotionRow.promotionRuleCountrys){
                    const special = goodsSpecialMap.get(prc.countryCode);
                    const price = priceMap.get(prc.countryCode)
                    let computePrice = getComputePrice(price)

                    // 校验促销的开始和结束时间
                    if(prc.time.length === 0){
                        return false;
                    }

                    // 促销数量 小于销售倍数不合规 大于库存数量不合规
                    if(prc.promotionCount <= 0  || prc.promotionCount < special.saleDoubleEbp || prc.promotionCount > special.ecomRemainQty){
                        return false
                    }
                    let promotionPrice = null;
                    // 促销类型是折扣的话，范围是0 <= x <= 100
                    if(prc.promotionType === 'Discount'){
                        if((prc.promotionValue < 0 || prc.promotionValue > 100)){
                            return false;
                        }
                        // 根据折扣计算促销价格
                        promotionPrice = (computePrice - (prc.promotionValue / 100) * computePrice).toFixed(2)

                    }
                    // 促销类型是价格的话，促销价格不能大于划线价
                    if(prc.promotionType === 'Price'){
                        if(prc.promotionValue > computePrice){
                            return false
                        }
                        // 价格促销的促销价格就是promotionValue
                        promotionPrice = prc.promotionValue
                    }

                    // 促销价格不能低于最低价格
                    if(promotionPrice && promotionPrice < price.lowestPrice){
                        return false
                    }
                    //  同一用户限购和单比订单限购必填
                    if(!(prc.userRestrictions != null && prc.orderRestrictions != null)){
                        return false
                    }

                }

                return true;
            }
            this.formLoading = true
            fileReader.onload = (ev) => {
                try {
                    const data = ev.target.result;
                    const workbook = read(data, {
                        type: 'binary'
                    });
                    const wsname = workbook.SheetNames[0];
                    const ws = utils.sheet_to_json(workbook.Sheets[wsname]);
                    console.log('ws', ws)
                    const listQuery = {
                        skuList: ws.map(item => { return item.SKU }),
                        quantityGoodsAgg: 'false',
                        page: 1,
                        size: 50
                    }

                    if(this.preSaleCountryFlag !==null){
                        listQuery.preSaleCountryFlag = this.preSaleCountryFlag
                    }

                    this.$store.dispatch('postGoodsSearch', listQuery).then((res) => {
                        res.list.forEach((item) => {
                            item.formListQuery = {
                                "title": '',
                                "brandCode": "",
                                "goodId": "",
                                "status": false,
                                "type": "1",
                                'pageDataSelectList': [],
                                "promotionRuleCountrys": []
                            }
                            const priceMap = new Map();
                            item.prices.forEach(s => {
                                priceMap.set(s.countryCode,s)
                            })
                            const goodsSpecialMap = new Map();
                            ws.forEach((item2) => {
                                if (item.sku == item2.SKU) {
                                    let countryCodeArr = []
                                    item.goodsSpecials.forEach(s => {
                                        if (s.shelf) {
                                            countryCodeArr.push(s.countryCode)
                                            goodsSpecialMap.set(s.countryCode,s)
                                        }
                                    })
                                    item.formListQuery.title = item2[this.$i18n.t("common.PromotionName")]
                                    item.formListQuery.goodId = item.goodsId
                                    if (item2[this.$i18n.t("common.PromotionObjectType")] == this.$i18n.t("common.NewUser")) {
                                        item.formListQuery.newUserStatus = item2[this.$i18n.t("common.Enableornot")] == this.$i18n.t("common.Yes") ? true : false
                                    } else {
                                        item.formListQuery.status = item2[this.$i18n.t("common.Enableornot")] == this.$i18n.t("common.Yes") ? true : false
                                    }
                                    const promotionCountry = item2[this.$i18n.t("common.PromotionCountry")]
                                    if (promotionCountry && countryCodeArr.length > 0 && countryCodeArr.indexOf(this.strChange(promotionCountry)) != -1) {
                                        let obj = {
                                            userType: item2[this.$i18n.t("common.PromotionObjectType")] == this.$i18n.t("common.NewUser") ? 'NEW' : 'OLD',
                                            beginTime: this.retFormat(item2[this.$i18n.t("common.PromotionStartTime")]),
                                            countryCode: this.strChange(item2[this.$i18n.t("common.PromotionCountry")]),
                                            coupon: item2[this.$i18n.t("common.Doyousupporttheuseofcoupons")] == this.$i18n.t("common.Yes") ? true : false,
                                            discount: '',
                                            endTime: this.retFormat(item2[this.$i18n.t("common.PromotionEndTime")]),
                                            flagShowPromotionPrice: item2[this.$i18n.t("common.Displaypromotionalprices")] == this.$i18n.t("common.Yes") ? true : false,
                                            freeDelivery: item2[this.$i18n.t("common.Freeshippingornot")] == this.$i18n.t("common.Yes") ? true : false,
                                            goodId: item.goodsId,
                                            orderRestrictions: item2[this.$i18n.t("common.Singleorderlimit")],
                                            promotionCount: item2[this.$i18n.t("common.Promotionquantity")],
                                            virtualQty: item2[this.$i18n.t("common.VirtualQuantity")],
                                            promotionId: '',
                                            promotionType: item2[this.$i18n.t("common.PromotionType")] == this.$i18n.t("common.discount") ? 'Discount' : 'Price',
                                            promotionValue: item2[`${this.$i18n.t("common.discount")}/${this.$i18n.t("common.price")}`],
                                            status: item2[this.$i18n.t("common.EnabledOrNot")] == this.$i18n.t("common.Yes") ? true : false,
                                            seckill: item2[this.$i18n.t("common.Isitaflashkill")] == this.$i18n.t("common.Yes") ? true : false,
                                            soldOut: item2[this.$i18n.t("common.SoldOutOrNot")] == this.$i18n.t("common.Yes") ? true : false,
                                            sort: item2[this.$i18n.t("common.Secondkillsorting")],
                                            time: [dayUtils.getUnix(this.retFormat(item2[this.$i18n.t("common.PromotionStartTime")])), dayUtils.getUnix(this.retFormat(item2[this.$i18n.t("common.PromotionEndTime")]))],
                                            userRestrictions: item2[this.$i18n.t("common.Purchaserestrictionsforthesameuser")]
                                        }
                                        item.formListQuery.promotionRuleCountrys.push(obj)
                                    }
                                }

                            })
                            item.formListQuery.promotionCheckResult = validatePromotion(item.formListQuery, goodsSpecialMap, priceMap)
                        })
                        // 如果商品商品促销国家没有上架，则忽略这条导入数据
                        res.list = res.list.filter((item) => item.formListQuery.promotionRuleCountrys.length > 0)
                        this.pageDataSelectList = res.list;
                        this.tableList = JSON.parse(JSON.stringify(this.pageDataSelectList))
                        this.$refs.file.value = null;
                        this.formLoading = false
                    }).catch(err => {
                        this.formLoading = false
                    });
                } catch (e) {
                    this.formLoading = false
                    this.$refs.file.value = null;
                    return false;
                }
            };
            fileReader.readAsBinaryString(file);
        },
        //处理Excel用户输入的国家小写转大写
        strChange(arg) {
            let str = arg.split('');
            for (let i = 0; i < str.length; i++) {
                if (str[i].charAt() >= "a" && str[i].charAt() <= "z") {
                    str[i] = str[i].toUpperCase();
                }
            }
            return str.join('');
        },
        //
        retFormat(numb) {
            const old = numb - 1;
            const t = Math.round((old - Math.floor(old)) * 24 * 60 * 60);
            const time = new Date(1900, 0, old, 0, 0, t)
            return new Date(time).getTime()
        },
        handleSelected() {
            if (!this.pageDataSelectList || this.pageDataSelectList.length <= 0) {
                this.$message({
                    message: this.$i18n.t("common.Pleaseselectaproductfirst"),
                    type: 'warning',
                    duration: 1000
                });
                return
            }
            if (this.pageDataSelectList.length > 50) {
                this.pageDataSelectList = this.pageDataSelectList.slice(0, 50)
            }
            this.tableList = JSON.parse(JSON.stringify(this.pageDataSelectList))
            this.dialogFormTableVisible = false
        },
        handleCurrentChange(val) {
            this.listQuery.page = val;
            let data = {
				isUsed:this.listQuery.effectShelf,
			}
            this.handleSearchList(data,1);
        },
        handleResetSearch() {
            this.category = ''
            this.$emit('handleResetSearch2');
        },
        //获取商品树类目
        getGoodsClassifyTree() {
            this.$store.dispatch('getGoodsClassifyTree', {}).then((res) => {
                this.$set(this.dictionary, 'classifyTree', res);
            });
        },
        //设置促销
        setup(row, $index) {
            let arr=[]
            let tableList = JSON.parse(JSON.stringify(this.tableList))
            if (row.goodsSpecials.length > 0) {
                row.goodsSpecials.forEach((item, index) => {
                    if (item.shelf==true) {
                        arr.push(item);
                    }
                });
            }
            row.goodsSpecials=arr
            console.log('row', row.goodsSpecials)
            let data = {
                val: [row],
                index: $index,
                tableList
            }
            this.$emit('setup', data)
        },
        //删除
        del(row) {
            this.tableList.forEach((item, index) => {
                if (row.goodsId == item.goodsId) {
                    this.tableList.splice(index, 1);
                }
            })
        },
        //获取组件穿过来的类目
        handleChangeCategory(val) {
            if (val) {
                this.category = val[val.length - 1];
            } else {
                this.category = ''
            }
        },
        onSubmit() {
            let result = this.tableList.every(item => { return item.formListQuery && item.formListQuery.promotionCheckResult })
            if (result == false) {
                this.$message({
                    message: this.$i18n.t("common.PleaseSetAllPromotionalProducts"),
                    type: 'warning',
                    duration: 1000
                });
                return
            }
            this.setInfoBeforeSubmit()
            this.loading = true
            let proArray = this.tableList.map(item => { return item.formListQuery })
            this.$store.dispatch('addPromotions', proArray).then((res) => {
                // v1.9.0 促销时间重叠则阻断
                if(res.success){
                    this.loading = false
                    this.$message({
                        message: this.$i18n.t("common.Successfullysubmitted"),
                        type: 'success',
                        duration: 1000
                    });
                    this.$emit('closeAddDialog')
                    this.$emit('handleSearchList')
                }else{
                    this.dialogTimeOverlayVisible = true
                    this.checkTimeOverlay = JSON.parse(JSON.stringify(res.promotionCountryCheckResultDtoList))
                    this.loading = false;
                }

            }).catch(err => {
                setTimeout(() => {
                    this.loading = false
                }, 500)
            });
        },
        // 设置用户购买数和单笔订单限购
        setInfoBeforeSubmit(){
            this.tableList.forEach(p => {
                p.formListQuery.promotionRuleCountrys.forEach(prc => {
                    // 1、计算用户购买数
                    p.goodsSpecials.forEach(goodsSpecial => {
                        if (goodsSpecial.countryCode == prc.countryCode) {
                            prc.userBuy = Math.floor(prc.promotionCount / goodsSpecial.saleDoubleEbp)
                        }
                    })
                    // 2、设置单笔订单限购
                    this.handleOrderPurchaseLimit(prc)
                })
            })
        },
        handleOrderPurchaseLimit,
        isShowGoodsPromotion,
        //获取促销价格
        getPromotionGoodsPrice,
    },
    mounted() {
        this.tableList = []
        this.getGoodsClassifyTree()
        this.handleSearchList();
    }
};
</script>
<style scoped>
.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
</style>
