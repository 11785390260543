var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("common.Prompt"),
            visible: _vm.dialogTimeOverlayVisible,
            width: "40%",
            "append-to-body": "",
            "before-close": _vm.handleTimeOverlayClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogTimeOverlayVisible = $event
            }
          }
        },
        [
          _vm.dialogTimeOverlayVisible
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { width: "80%" } },
                      _vm._l(_vm.checkTimeOverlay, function(item, index) {
                        return _c(
                          "div",
                          { key: index, staticStyle: { margin: "10px 10px" } },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.handleTipTranslation(item)) +
                                "\n                        "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer"
                  },
                  [
                    _c(
                      "el-button",
                      { on: { click: _vm.handleTimeOverlayClose } },
                      [_vm._v(_vm._s(_vm.$t("common.Confirm")))]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }