<template>
    <div>
        <el-form :model="formListQuery" ref="variantFrom" :rules="rules">
            <el-form-item :label="$t('common.PromotionName')" prop="title" :label-width="formLabelWidth">
                <el-input show-word-limit maxlength="50" @input="changeCheckList" v-model="formListQuery.title" :placeholder="$t('common.Pleaseentercontent')"
                          class="input-width"></el-input>
            </el-form-item>
            <el-form-item :label="$t('common.Selectproduct')" :label-width="formLabelWidth">
                <template slot-scope="scope">
                    <el-button type="success" @click="changeGoods">{{ $t('common.Selectproduct') }}</el-button>
                </template>
            </el-form-item>

            <div v-if="formListQuery.pageDataSelectList && formListQuery.pageDataSelectList.length > 0">
                <el-form-item :label-width="formLabelWidth">
                    <el-table ref="pageDataSelect" :data="formListQuery.pageDataSelectList" border style="width: 100%">
                        <el-table-column prop="goodsId" :label="$t('common.ProductID')" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="goodsCode" :label="$t('common.SKU_EAN_Encoding')" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span>SKU:{{ scope.row.sku }}</span
                                ><br/>
                                <span>EAN:{{ scope.row.barCode }}</span
                                ><br/>
                                <span>{{ $t('common.coding') }}:{{ scope.row.goodsCode }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="imageUrl" :label="$t('common.Productimage1')" align="center">
                            <template slot-scope="scope">
<!--                                <el-image-->
<!--                                        style="height: 100px"-->
<!--                                        v-if="scope.row.imageUrl"-->
<!--                                        :src="ossHost + scope.row.imageUrl"-->
<!--                                        fit="fill"-->
<!--                                ></el-image>-->
                                <listImage :imgUrl="scope.row.imageUrl"></listImage>
                            </template>
                        </el-table-column>
                        <el-table-column prop="title" :label="$t('common.ProductName')" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="goodsSpecials" :label="$t('common.Countrieslisted')" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.goodsSpecials" :key="s.countryCode">
                                    <span v-if="s.shelf==true"> {{ $dict.getDictValue('countrys', s.countryCode) }}<br/> </span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="prices" :label="$t('common.inventory')" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                                    {{ s.countryCode }}:{{ s.ecomRemainQty }}<br/>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="packageQuantity" :label="$t('common.PackingQuantity')" align="center"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column prop="prices" :label="$t('common.Fixedprice1')" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.prices" :key="index">
                                    <span v-if="s.fixedPrice">{{ s.countryCode }}:{{
                                        Number(s.fixedPrice).toFixed(2)
                                        }}{{ $dict.getDictCurrencyValue(s.countryCode) }}<br/></span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="prices" :label="$t('common.Lowestprice')" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.prices" :key="index">
                                    <span v-if="s.lowestPrice">{{ s.countryCode }}:{{
                                        Number(s.lowestPrice).toFixed(2)
                                        }}{{ $dict.getDictCurrencyValue(s.countryCode) }}<br/></span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="brand" :label="$t('common.fixedprice')" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.prices" :key="index">
                                    <span v-if="s.resultPrice">{{ s.countryCode }}:{{
                                        s.resultPrice.toFixed(2)
                                        }}{{ $dict.getDictCurrencyValue(s.countryCode) }}<br/></span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="brand" :label="$t('common.price1')" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-for="(s, index) in scope.row.prices" :key="index">
                                    <span v-show="s.salePriceEbp || s.salePriceEbp>=0">{{
                                        s.countryCode
                                        }}:{{
                                        s.salePriceEbp.toFixed(2)
                                        }}{{ $dict.getDictCurrencyValue(s.countryCode) }}<br/></span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="shelf" :label="$t('common.state')" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span v-if="scope.row.shelf == true" style="color: #46a8a0">{{ $t('common.Listed') }}</span>
                                <span v-else>{{ $t('common.Notlisted') }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>

                <el-tabs @tab-click="changeUserPromotionTabs">
                    <el-tab-pane :label="$t('common.NewUserPromotion')">
                        <user-promotion-dialog ref="newUserPromotion" :is-edit="isEdit" :price-setting="priceSetting" :form-data="newUserFormData">
                        </user-promotion-dialog>
                    </el-tab-pane>

                    <el-tab-pane :label="$t('common.RegularUserPromotion')">
                        <user-promotion-dialog ref="oldUserPromotion" :is-edit="isEdit" :price-setting="priceSetting" :form-data="oldUserFormData">
                        </user-promotion-dialog>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </el-form>

        <div slot="footer" class="dialog-footer">
            <el-button style="width: 20%" @click="closeDialog">{{ $t('common.cancel') }}</el-button>
            <el-button style="width: 20%" type="primary" @click="submitBefore('variantFrom')">{{ $t('common.confirm1') }}</el-button>
        </div>

        <el-dialog :title="$t('common.Selectproduct')" v-if="dialogFormTableVisible2" :visible.sync="dialogFormTableVisible2"
                   @close="closeDialogGoods" append-to-body>
            <div>
                <FormTableDialog
                        :argFrom="argFrom2"
                        :dictionary="dictionary"
                        :placeholderFrom="placeholderFrom2"
                        :pageDataSelectList="formListQuery.pageDataSelectList"
                        @handleSearchList="handleSearchList2"
                        @handleResetSearch="handleResetSearch2"
                        @handleSelected="handleSelected"
                >
                    <template v-slot:tableSlot>
                        <el-table
                                ref="tableData"
                                :data="pageData2.list"
                                :tree-props="{children: 'packageQtyList', hasChildren: 'hasChildren'}"
                                id="goodsTable"
                                style="width: 100%"
                                border
                                row-key="goodsId"
                                :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }"
                                default-expand-all
                        >
                            <el-table-column width="55" align="center" type=''>
                                <template slot-scope="scope">
                                    <div class="seRadio">
                                        <el-radio v-model="selectionId" :label="scope.row.goodsId"
                                                  @change="selectionChange(scope.row)"
                                                  :disabled="!scope.row.checkboxStatus"
                                        >{{ '' }}
                                        </el-radio>
                                    </div>
                                </template>
                            </el-table-column>
                            <!-- 用于显示子表格张开按钮 -->
                            <el-table-column prop="quantityType" label="" width="50px" align="center">
                            </el-table-column>
                            <el-table-column prop="goodsId" :label="$t('common.ProductID')" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="goodsCode" :label="$t('common.SKU_EAN_Encoding')" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>SKU:{{ scope.row.sku }}</span
                                    ><br/>
                                    <span>EAN:{{ scope.row.barCode }}</span
                                    ><br/>
                                    <span>{{ $t('common.coding') }}:{{ scope.row.goodsCode }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="imageUrl" :label="$t('common.Productimage1')" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-image
                                            style="height: 100px"
                                            v-if="scope.row.imageUrl"
                                            :src="ossHost + scope.row.imageUrl"
                                            fit="fill"
                                    ></el-image>
                                </template>
                            </el-table-column>
                            <el-table-column prop="packageQuantity" :label="$t('common.PackingQuantity')" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="title" :label="$t('common.ProductName')" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="goodsSpecials" :label="$t('common.Countrieslisted')" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-for="(s, index) in scope.row.goodsSpecials" :key="index">
                                        <span v-if="s.shelf">{{
                                            $dict.getDictValue('countrys', s.countryCode)
                                            }}<br/></span>
                                    </span>
                                </template>
                            </el-table-column>
<!--                            <el-table-column prop="endTime" label="促销价" align="center" show-overflow-tooltip>
                                <template slot-scope="scope" v-if="scope.row.prices.length > 0">
                                        <span v-for="(s, index) in scope.row.prices" :key="index">
                                            {{ $dict.getDictValue('countrys', s.countryCode) }}:<span
                                                v-if="s.promotionPrice">{{
                                            s.promotionPrice.toFixed(2)
                                            }}{{ $dict.getDictCurrencyValue(s.countryCode) }}</span
                                        ><br/>
                                        </span>
                                </template>
                            </el-table-column>-->
                            <el-table-column prop="prices" :label="$t('common.Promotionprice1')" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'NEW')">
                                        <span style="font-weight:bold;text-align: left;display: block;">{{ $t('common.NewUserPromotion') }}<br/></span>
                                        <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'NEW'">
                                            {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                                        </span>
                                    </span>
                                    <span v-if="isShowGoodsPromotion(scope.row.promotionRuleCountrys, 'OLD')">
                                        <span style="font-weight:bold;text-align: left;display: block;">{{ $t('common.RegularUserPromotion') }}<br/></span>
                                        <span v-for="(promotion, index) in scope.row.promotionRuleCountrys" :key="index" v-if="promotion.userType == 'OLD'">
                                            {{ $dict.getDictValue('countrys', promotion.countryCode) }}:{{ getPromotionGoodsPrice(promotion, scope.row.prices) }}<br/>
                                        </span>
                                    </span>
                                    <span v-if="!scope.row.promotionRuleCountrys || scope.row.promotionRuleCountrys.length == 0">{{ $i18n.t("common.ThereAreCurrentlyNoProductPromotions") }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="prices" :label="$t('common.fixedprice')" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-for="(s, index) in scope.row.prices" :key="index">
                                        {{ $dict.getDictValue('countrys', s.countryCode) }}:<span v-if="s.resultPrice">{{
                                        s.resultPrice.toFixed(2)
                                        }}{{ $dict.getDictCurrencyValue(s.countryCode) }}</span
                                    ><br/>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="prices" :label="$t('common.Fixedprice1')" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-for="(s, index) in scope.row.prices" :key="index">
                                        <span v-if="s.fixedPrice">{{ s.countryCode }}:{{
                                            Number(s.fixedPrice).toFixed(2)
                                            }}{{ $dict.getDictCurrencyValue(s.countryCode) }}<br/></span>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="prices" :label="$t('common.Lowestprice')" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-for="(s, index) in scope.row.prices" :key="index">
                                        <span v-if="s.lowestPrice">{{ s.countryCode }}:{{
                                            Number(s.lowestPrice).toFixed(2)
                                            }}{{ $dict.getDictCurrencyValue(s.countryCode) }}<br/></span>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="shelf" :label="$t('common.state')" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span v-if="scope.row.shelf == true" style="color: #46a8a0">{{ $t('common.Listed') }}</span>
                                    <span v-else>{{ $t('common.Notlisted') }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="block">
                            <el-pagination
                                    @current-change="handleCurrentChange"
                                    :current-page="listQuery.page"
                                    :page-size="listQuery.size"
                                    layout="total, prev, pager, next, jumper"
                                    :total="pageData2.total"
                            >
                            </el-pagination>
                        </div>
                    </template>
                </FormTableDialog>
            </div>
        </el-dialog>

        <el-dialog
                :title="$t('common.SubmitConfirmation')"
                :visible.sync="dialogGetPricingVisible"
                width="30%"
                append-to-body
                :before-close="handleGetPricingClose"
        >
            <div v-if="dialogGetPricingVisible">
                <div style="width: 100%; display: flex; justify-content: center; align-items: center">
                    <div style="width: 80%">
                        <div v-for="(item, index) in getPricing" :key="index" style="margin: 10px 10px">
                            {{ $dict.getDictValue('countrys', item.countryCode) }}（{{item.userType == 'NEW' ? $t('common.NewUserPromotion') : $t('common.RegularUserPromotion')}}）{{ $t('common.TheCurrentGrossProfitMarginOfTheCurrentActivityPriceIs')}}<span
                                style="color: red"
                        >{{ item.profit.toFixed(2) }}%</span
                        >
                        </div>
                    </div>
                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogGetPricingVisible=false">{{ $t('common.Cancel') }}</el-button>
                    <el-button type="primary" :loading="loading" @click="finallySubmit(isEdit)">{{ $t('common.Confirm') }}</el-button>
                </span>
            </div>
        </el-dialog>

        <el-dialog
                :title="$t('common.Prompt')"
                :visible.sync="dialogLowestPriceVisible"
                width="20%"
                append-to-body
                :before-close="handleLowestPriceClose"
        >
            <div v-if="dialogLowestPriceVisible">
                <h4><span>{{ $t('common.ThePromotionalPriceCannotBeLowerThanTheLowestPrice') }}：</span></h4>
                <div style="width: 100%; display: flex; justify-content: center; align-items: center">
                    <div style="width: 80%">
                        <div v-for="(item, index) in checkLowestPrice" :key="index" style="margin: 10px 10px">
                            {{
                            $dict.getDictValue('countrys', item.countryCode)
                            }}（{{ item.userType == 'NEW' ? $t('common.NewUserPromotion') : $t('common.RegularUserPromotion') }}）:<span style="color: red"
                        >{{ item.price.toFixed(2) }}</span
                        >
                        </div>
                    </div>
                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogLowestPriceVisible=false">{{ $t('common.Confirm') }}</el-button>
                </span>
            </div>
        </el-dialog>

        <TimeOverlayDialog :dialog-time-overlay-visible='dialogTimeOverlayVisible' :check-time-overlay='checkTimeOverlay'
                           @dialogVisibleEvent='handleTimeOverlayClose'></TimeOverlayDialog>
    </div>
</template>
<script>
import {showSelectOfSelectable} from "@/utils/treeTable";
import FilterContainer from '@/components/common/FilterContainer.vue';
import FormTableDialog from '@/components/common/FormTableDialog.vue';
import userPromotionDialog from './userPromotionDialog.vue';
import {getPromotionGoodsPrice, isShowGoodsPromotion} from "@/utils/promotion";
import i18n from '@/i18n/i18n';
import listImage from '@/components/common/ListImage.vue';
import TimeOverlayDialog from '@/views/promotion/components/TimeOverlayDialog.vue';

export default {
    components: {
        TimeOverlayDialog,
        FilterContainer,
        FormTableDialog,
        userPromotionDialog,
        listImage
    },
    props: {
        isEdit: {
            type: Boolean,
            default: false
        },
        formListQuery: {
            type: Object,
            default: () => {
                return {};
            }
        },
        dictionary: {
            type: Object,
            default: () => {
                return {};
            }
        },
        placeholderFrom: {
            type: Object,
            default: () => {
                return {
                    name: i18n.t('common.IDName'),
                    state: i18n.t('common.ActivityStatus'),
                    date: {
                        start: i18n.t('common.Startdate'),
                        end: i18n.t('common.Enddate')
                    },
                    date2: {
                        start: i18n.t('common.Startdate'),
                        end: i18n.t('common.Enddate')
                    }
                };
            }
        },
        proAddData: {
            type: Object,
            default: () => {
                return {};
            }
        },
        preSaleCountryFlag: {
            type: Boolean,
            default: () => {
                return null
            }
        },
        priceSetting: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
            priceTip: i18n.t('common.AutomaticallyCalculateAfterEnteringPrice'),
            discountTip: i18n.t('common.AutomaticallyCalculateAfterEnteringTheDiscount'),
            selectionId: '',
            loading: false,
            Discount: null,
            Price: null,
            profit: null,
            promotionValue1: null,
            promotionValue2: null,
            promotionCount: null,
            promotionType: 'Discount',
            freeDelivery: false,
            checkLowestPrice: [],
            checkTimeOverlay: [],
            //父级传过来的数据
            busData: {},
            getPricing: [],
            //促销
            newUserCountryCode: [],
            countryCode: [],
            arrayDate: [],
            ossHost: process.env.VUE_APP_OSS_Head,
            pageData2: {},
            listQuery: {
                countryCode: '',
                classifyId: '',
                idFuzzy: '',
                brandCode: '',
                updateUserId: '', //操作人
                inPromotion: '', //是否促销
                isOnShelf: '', //是否上架
                beginCreateTime: '',
                endCreateTime: '',
                beginUpdateTime: '',
                endUpdateTime: '',
                page: 1,
                size: 10
            },
            defaultListQuery: {
                countryCode: '',
                classifyId: '',
                idFuzzy: '',
                brandCode: '',
                updateUserId: '', //操作人
                inPromotion: '', //是否促销
                isOnShelf: '', //是否上架
                beginCreateTime: '',
                endCreateTime: '',
                beginUpdateTime: '',
                endUpdateTime: '',
                page: 1,
                size: 10
            },
            inputWidth: '70%',
            formLabelWidth: '150px',
            form: {},
            //被选中的商品
            selectedRow: {},
            //组件标签是否显示
            argFrom2: {
                name: true,
                promotion: true,
                useState: true
            },
            placeholderFrom2: {
                name: i18n.t('common.SKU_EAN_Encoding'),
                promotion: i18n.t('common.Whethertopromoteornot'),
                receiveState: i18n.t('common.state')
            },
            dialogFormTableVisible2: false,
            dialogGetPricingVisible: false,
            dialogLowestPriceVisible: false,//校验最低价弹窗
            dialogTimeOverlayVisible: false,//促销时间重叠检验弹窗
            //选中数量
            rules: {
                title: [
                    {
                        required: true,
                        message: i18n.t('common.Pleaseenteraname'),
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 50,
                        message: i18n.t('common.Between1and50charactersinlength'),
                        trigger: 'blur'
                    }
                ]
            },
            newUser: 'NEW',
            newUserFormData: {},
            oldUser: 'OLD',
            oldUserFormData: {},
            formData: {
                title: null,
                goodId: null,
                newUserStatus: null,
                status: null,
                newUserPromotionRuleCountrys: [],
                oldUserPromotionRuleCountrys: []
            }
        };
    },
    methods: {
        initData() {
            if (this.isEdit) {
                this.$nextTick(() => {
                    let goodId = '';
                    if (this.formListQuery.goodsId) {
                        goodId = this.formListQuery.goodsId;
                    } else if (this.formListQuery.goodId) {
                        goodId = this.formListQuery.goodId;
                    }
                    this.handleSearchList3(goodId);
                });
            } else {
                if(this.proAddData && this.proAddData.tableList[this.proAddData.index] && this.proAddData.tableList[this.proAddData.index].formListQuery){
                    //促销导入
                    this.countryCode=[];
                    this.formListQuery.goodsMergeListMgrDto=this.proAddData.val[0];
                    this.formListQuery.promotionRuleCountrys=this.proAddData.tableList[this.proAddData.index].formListQuery.promotionRuleCountrys;
                    this.formListQuery.title=this.proAddData.tableList[this.proAddData.index].formListQuery.title;
                    this.formListQuery.status=this.proAddData.tableList[this.proAddData.index].formListQuery.status == true ? true : false;
                    this.formListQuery.newUserStatus=this.proAddData.tableList[this.proAddData.index].formListQuery.newUserStatus == true ? true : false;
                    //编辑
                    this.editForFormData()

                } else {
                    //新增
                    this.addForFormData()
                }
                this.$forceUpdate();
            }
            this.handleSearchList2();
        },
        addForFormData() {
            //新增
            let countryCodes = [];
            if (this.formListQuery.pageDataSelectList && this.formListQuery.pageDataSelectList.length > 0) {
                this.formListQuery.pageDataSelectList[0].goodsSpecials.forEach((item, index) => {
                    countryCodes.push(item.countryCode);
                });
            }

            this.newUserFormData = JSON.parse(JSON.stringify(this.formListQuery))
            this.oldUserFormData = JSON.parse(JSON.stringify(this.formListQuery))

            this.newUserFormData = {
                ...this.newUserFormData,
                status: false,
                countryCodes: countryCodes,
                countryCode: [],
                activeName: '',
                promotionRuleCountrys: []
            }
            this.oldUserFormData = {
                ...this.oldUserFormData,
                status: false,
                countryCodes: countryCodes,
                countryCode: [],
                activeName: '',
                promotionRuleCountrys: []
            }
        },
        editForFormData() {
            let countryCodes = []
            this.formListQuery.pageDataSelectList[0].goodsSpecials.forEach(item => {
                if (item.shelf) {
                    countryCodes.push(item.countryCode);
                }
            });
            this.formListQuery.countryCodes = countryCodes
            this.newUserFormData = JSON.parse(JSON.stringify(this.formListQuery))
            this.oldUserFormData = JSON.parse(JSON.stringify(this.formListQuery))
            this.newUserFormData.status = this.formListQuery.newUserStatus
            this.newUserFormData.promotionRuleCountrys = this.newUserFormData.promotionRuleCountrys.filter(item => item.userType == 'NEW')
            this.oldUserFormData.promotionRuleCountrys = this.oldUserFormData.promotionRuleCountrys.filter(item => item.userType == 'OLD')

            //已选择国家
            let newUserCountryCode = []
            this.newUserFormData.promotionRuleCountrys.forEach((item) => {
                newUserCountryCode.push(item.countryCode);
            });
            this.newUserFormData.countryCode = newUserCountryCode
            let oldUserCountryCode = []
            this.oldUserFormData.promotionRuleCountrys.forEach((item) => {
                oldUserCountryCode.push(item.countryCode);
            });
            this.oldUserFormData.countryCode = oldUserCountryCode

            this.newUserFormData.activeName = this.newUserFormData.countryCode.length > 0 ? this.newUserFormData.countryCode[0] : '';
            this.oldUserFormData.activeName = this.oldUserFormData.countryCode.length > 0 ? this.oldUserFormData.countryCode[0] : '';


            this.newUserFormData.countryCodes = Array.from(new Set(countryCodes.concat(newUserCountryCode)))
            this.oldUserFormData.countryCodes = Array.from(new Set(countryCodes.concat(oldUserCountryCode)))

            this.$forceUpdate()
        },
        closeDialogGoods() {
            this.dialogFormTableVisible2 = false
            this.listQuery = Object.assign({}, this.defaultListQuery)
            this.handleSearchList2();
        },
        changeCheckList(e) {
            this.$forceUpdate();
        },
        handleGetPricingClose() {
            this.dialogGetPricingVisible = false;
        },
        handleLowestPriceClose() {
            this.dialogLowestPriceVisible = false;
        },
        handleSearchList3(goodId) {
            const data = [].concat(goodId);
            this.$store.dispatch('postPromotionId', data).then((res) => {
                let shelfArr = []; //上架国家数组

                if (res && res.length > 0 && res[0].goodsSpecials.length > 0) {
                    res[0].goodsSpecials.forEach((item, index) => {
                        if (item.shelf) {
                            shelfArr.push(item.countryCode);
                        }
                    });
                    this.formListQuery.countryCodes = shelfArr
                }
                this.$set(this.formListQuery, 'pageDataSelectList', res);
                this.editForFormData()
            });
        },
        handleSearchList2(listQuery, type) {
            let curSates = {
                page: this.listQuery.page,
                size: this.listQuery.size,
            }
            this.argFrom2.promotion = false;

            if (listQuery) {
                if (type != 1) {
                    this.listQuery.page = 1;
                }
                let isOnShelf = listQuery.isUsed;
                let inPromotion = listQuery.promotion;
                this.listQuery = {
                    countryCode: '',
                    classifyId: '',
                    idFuzzy: listQuery.title,
                    brandCode: '',
                    updateUserId: '', //操作人
                    inPromotion: inPromotion, //是否促销
                    effectShelf: isOnShelf, //是否上架
                    beginCreateTime: '',
                    endCreateTime: '',
                    beginUpdateTime: '',
                    endUpdateTime: '',
                    page: this.listQuery.page,
                    size: this.listQuery.size
                };
            }

            if (this.preSaleCountryFlag !== null) {
                this.listQuery.preSaleCountryFlag = this.preSaleCountryFlag
            }

            let temp = JSON.stringify(this.listQuery, (key, value) => {
                if (value !== null && value !== '') {
                    return value;
                }
            });
            curSates = JSON.parse(temp);
            this.$store.dispatch('postGoodsSearch', curSates).then((res) => {
                this.pageData2 = res;
                // 回显勾选
                if (this.$refs.tebleData) {
                    showSelectOfSelectable(this.$refs.tebleData, this.pageData2.list)
                }

            });
        },
        //选择商品
        changeGoods() {
            setTimeout(() => {
                if (this.$refs.tableData && this.formListQuery.pageDataSelectList &&
                    this.formListQuery.pageDataSelectList.length > 0) {
                    this.selectionId = this.formListQuery.pageDataSelectList[0].goodsId
                }
                this.$forceUpdate()
            })
            this.dialogFormTableVisible2 = true;
            // 回显勾选
            if (this.$refs.tebleData) {
                showSelectOfSelectable(this.$refs.tebleData, this.pageData2.list)
            }
        },
        closeDialog() {
            this.formListQuery.pageDataSelectList = [];
            this.$emit('closeDialog');
        },
        closeDialog3() {
            this.dialogFormTableVisible2 = false;
        },
        handleTimeOverlayClose(visible) {
            this.dialogTimeOverlayVisible = visible;
        },
        handleResetSearch2() {
            this.$emit('handleResetSearch2');
        },
        handleCurrentChange(val) {
            this.listQuery.page = val;
            let data = {
                promotion: this.listQuery.inPromotion,
                isUsed: this.listQuery.effectShelf
            }
            this.handleSearchList2(data, 1);
        },
        //单选获取选中的
        selectionChange(row) {
            this.selectedRow = Object.assign({}, row);
        },
        handleSearchList() {
            this.$emit('handleSearchList');
        },
        //选择商品
        handleSelected() {
            let tableList = []
            let arr = []
            if (this.isEdit == false) {
                tableList = JSON.parse(JSON.stringify(this.proAddData.tableList))
            }
            if (this.formListQuery.pageDataSelectList.length > 0 && !this.selectedRow) {
                this.selectedRow = Object.assign({}, this.formListQuery.pageDataSelectList[0])
            }
            if (!this.selectedRow) {
                this.$message({
                    message: this.$i18n.t('common.Pleaseselectaproductfirst'),
                    type: 'warning',
                    duration: 1000
                });
                return
            }
            if (!this.selectedRow.goodsSpecials || this.selectedRow.goodsSpecials.length <= 0) {
                this.$message({
                    message: this.$i18n.t('common.Nocountrieslisted'),
                    type: 'warning',
                    duration: 1000
                });
                return
            }
            if (tableList && tableList.length > 0) {
                let result = tableList.filter(item => {
                    return item.goodsId == this.selectedRow.goodsId
                })
                console.log('this.selectedRow', this.selectedRow, tableList, result)
                if (result.length > 0) {
                    this.$message({
                        message: this.$i18n.t('common.ProductSelected'),
                        type: 'warning',
                        duration: 1000
                    });
                    return
                }
            }
            this.formListQuery.pageDataSelectList = [];
            this.selectedRow.goodsSpecials.forEach((item) => {
                if (item.shelf == true) {
                    arr.push(item)
                }
            });
            this.selectedRow.goodsSpecials = arr
            this.formListQuery.pageDataSelectList.push(this.selectedRow);
            this.formListQuery.promotionRuleCountrys = [];
            this.closeDialogGoods()

            this.editForFormData()

            if (this.proAddData.index >= 0) {
                let data = {
                    val: this.selectedRow,
                    index: this.proAddData.index
                }
                this.$emit('handChangeSelectList', data)
            }
        },
        submitBefore(formName) {
            if (this.formListQuery.pageDataSelectList.length <= 0) {
                this.$message({
                    message: this.$i18n.t('common.Pleaseselectaproductfirst'),
                    type: 'warning',
                    duration: 1000
                });
                return;
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.onSubmit();
                }
            });
        },
        //出现毛利率提交
        finallySubmit(type) {
            this.loading = true;
            let url = '';
            type == true ? (url = 'putPromotion') : (url = 'addPromotion');
            if (url == 'putPromotion') {
                this.$store.dispatch(url, this.formListQuery).then((res) => {
                    // v1.9.0 促销时间重叠则阻断
                    if(res.success){
                        setTimeout(() => {
                            this.$message({
                                message: this.$i18n.t('common.SubmittedSuccessfully'),
                                type: 'success',
                                duration: 1000
                            });
                            this.closeDialog();
                            this.handleSearchList();
                            this.loading = false;
                        }, 500)
                    }else{
                        this.dialogGetPricingVisible = false
                        this.dialogTimeOverlayVisible = true
                        this.checkTimeOverlay = JSON.parse(JSON.stringify(res.promotionCountryCheckResultDtoList))
                        this.loading = false;
                    }
                }).catch((err) => {
                    this.loading = false;
                });
            } else {
                this.$store.dispatch('checkTimeOverlay', this.formListQuery).then((res) => {
                    if(res.success){
                        // 新增促销校验通过
                        this.formListQuery.promotionCheckResult = true
                        this.closeDialog();
                        this.loading = false;
                        let data = {
                            val: this.selectedRow,
                            index: this.proAddData.index,
                            formListQuery: this.formListQuery
                        }
                        this.$emit('queryPro', data)
                    }else{
                        this.dialogGetPricingVisible = false
                        this.dialogTimeOverlayVisible = true
                        this.checkTimeOverlay = JSON.parse(JSON.stringify(res.promotionCountryCheckResultDtoList))
                        this.loading = false;
                    }
                })

            }
        },
        //提交
        onSubmit() {
            let validateFlag = this.$refs.newUserPromotion.validateForm() && this.$refs.oldUserPromotion.validateForm()
            if (validateFlag) {
                this.$confirm(this.compareUserPromotionPrice()? this.$i18n.t('common.ThePromotionalPriceForNewUsersIsGreaterThanThePromotionalPriceForOldUsersDoYouWantToContinue') : this.$i18n.t('common.Whethertosubmitdata'), this.$i18n.t('common.Prompt'), {
                    confirmButtonText: i18n.t("common.confirm1"),
                    cancelButtonText: i18n.t("common.cancel"),
                    type: 'warning',
                    beforeClose: (action, ctx, close) => {
                        if (action !== 'confirm') {
                            close();
                            return;
                        }
                        ctx.confirmButtonLoading = true
                        let promotionRuleCountrys = this.getPromotionRuleCountrys();
                        let checkPrice = promotionRuleCountrys.map(item => {
                            let price = null
                            if (item.promotionType == "Price") {
                                price = item.promotionValue
                            } else {
                                price = item.discount
                            }
                            return {
                                price,
                                countryCode: item.countryCode,
                                userType: item.userType
                            }
                        });
                        let checkQuery = {
                            goodsId: this.formListQuery.pageDataSelectList[0].goodsId,
                            priceList: checkPrice
                        }
                        this.$store.dispatch('postCheckLowestPrice', checkQuery).then((resCheck) => {
                            if (resCheck) {
                                //校验最低价
                                if (resCheck.result == false) {
                                    ctx.confirmButtonLoading = false
                                    this.checkLowestPrice = resCheck.priceList
                                    if (this.checkLowestPrice && this.checkLowestPrice.length > 0) {
                                        this.dialogLowestPriceVisible = true
                                    }
                                    return
                                }

                                let flagPrice = true
                                this.formListQuery.goodId = this.formListQuery.pageDataSelectList[0].goodsId;
                                this.formListQuery.newUserStatus = this.newUserFormData.status
                                this.formListQuery.status = this.oldUserFormData.status
                                this.formListQuery.promotionRuleCountrys = promotionRuleCountrys

                                if (!this.isSelectedPromotionCountry(this.newUserFormData.status, this.newUserFormData.promotionRuleCountrys) ||
                                    !this.isSelectedPromotionCountry(this.oldUserFormData.status, this.oldUserFormData.promotionRuleCountrys)) {
                                    this.$message({
                                        message: this.$i18n.t("common.PleaseSelectPromotionCountry"),
                                        type: 'error',
                                        duration: 1000
                                    });
                                    ctx.confirmButtonLoading = false;
                                    return;
                                }

                                //毛利率
                                const pricingData = [];
                                this.formListQuery.promotionRuleCountrys.forEach((item, index) => {
                                    pricingData.push({
                                        countryCodes: item.countryCode,
                                        resultPrice: item.promotionType == 'Discount' ? item.discount : item.promotionValue,
                                        userType: item.userType
                                    });
                                });

                                let goodsList = this.formListQuery.pageDataSelectList[0]
                                if(this.isEdit){
                                    goodsList = this.formListQuery.goodsMergeListMgrDto;
                                }

                                pricingData.forEach((item, index) => {
                                    if (goodsList.prices && goodsList.prices.length > 0) {
                                        goodsList.prices.forEach((item2, index2) => {
                                            if (item.countryCodes == item2.countryCode) {
                                                item.originalPrice = item2.originalPrice;
                                            }
                                        });
                                    }
                                    if (goodsList.goodsSpecials && goodsList.goodsSpecials.length > 0) {
                                        goodsList.goodsSpecials.forEach((item2, index2) => {
                                            if (item.countryCodes == item2.countryCode) {
                                                item.saleDoubleEbp = item2.saleDoubleEbp;
                                            }
                                        });
                                    }
                                    if (goodsList.peso) {
                                        item.peso = goodsList.peso;
                                    }
                                });

                                setTimeout(() => {
                                    this.formListQuery.promotionRuleCountrys.forEach((item, index) => {
                                        this.formListQuery.pageDataSelectList[0].prices.forEach((item2, index2) => {
                                            if (item.countryCode == item2.countryCode) {
                                                if (!item2.resultPrice) {
                                                    flagPrice = false
                                                }
                                            }
                                        })
                                    })
                                    if (flagPrice == false) {
                                        this.$message({
                                            message: this.$i18n.t('common.ProductHasNoPricing'),
                                            type: 'error',
                                            duration: 1000
                                        });
                                        ctx.confirmButtonLoading = false;
                                        return
                                    }
                                    if(pricingData.length > 0){
                                        this.$store.dispatch('getPricing', pricingData).then((res) => {
                                            if (res) {
                                                this.getPricing = res;
                                                setTimeout(() => {
                                                    this.dialogGetPricingVisible = true;
                                                    ctx.confirmButtonLoading = false
                                                    close();
                                                }, 500)
                                            }
                                        }).catch(err => {
                                            setTimeout(() => {
                                                ctx.confirmButtonLoading = false
                                            }, 500)
                                        });
                                    }else{
                                        close()
                                        ctx.confirmButtonLoading = false
                                        this.finallySubmit(this.isEdit)
                                    }

                                }, 50)
                            }
                        }).catch(errCheck => {
                            ctx.confirmButtonLoading = false
                        });
                    }
                }).catch(function (error) {
                    console.log(error)
                })
            } else {
                this.$message({
                    message: this.$i18n.t('common.Verificationfailed'),
                    type: 'error',
                    duration: 1000
                });
                return false;
            }
        },
        //获取促销数据
        getPromotionRuleCountrys() {
            this.newUserFormData.promotionRuleCountrys.forEach(item => item.userType = 'NEW');
            this.oldUserFormData.promotionRuleCountrys.forEach(item => item.userType = 'OLD')
            let promotionRuleCountrys = []
            promotionRuleCountrys = promotionRuleCountrys.concat(this.newUserFormData.promotionRuleCountrys)
            promotionRuleCountrys = promotionRuleCountrys.concat(this.oldUserFormData.promotionRuleCountrys)
            promotionRuleCountrys.forEach((promotion, index) => {
                //计算用户购买数
                this.newUserFormData.pageDataSelectList[0].goodsSpecials.forEach(goodsSpecial => {
                    if (goodsSpecial.countryCode == promotion.countryCode) {
                        promotion.userBuy = Math.floor(promotion.promotionCount / goodsSpecial.saleDoubleEbp)
                    }
                })
                if (!promotion.seckill) {
                    promotion.sort = null;
                }
                if (promotion.time && promotion.time.length > 0) {
                    promotionRuleCountrys[index].beginTime = new Date(promotion.time[0]).getTime();
                    promotionRuleCountrys[index].endTime = new Date(promotion.time[1]).getTime();
                }
            })
            return promotionRuleCountrys;
        },
        //是否选择促销国家
        isSelectedPromotionCountry(status, promotionRuleCountrys) {
            if (!status) {
                return true
            }
            let newUserCountryCode = [];
            promotionRuleCountrys.forEach(item => {
                newUserCountryCode.push(item.countryCode);
            });
            if (newUserCountryCode.length > 0) {
                return true
            }
            return false
        },
        //解决老用户促销tab初始化未被选中问题
        changeUserPromotionTabs(tab) {
            if (tab.index == 0) {
                this.$refs.newUserPromotion.refreshTabsActive()
            } else {
                this.$refs.oldUserPromotion.refreshTabsActive()
            }
        },
        //对比用户促销价（新用户的促销价若大于老用户促销价）
        compareUserPromotionPrice() {
            let compareResult = false
            //都开启时才比对价格
            if (!this.newUserFormData.status || !this.oldUserFormData.status) {
                return compareResult;
            }
            this.newUserFormData.promotionRuleCountrys.forEach(item => {
                this.oldUserFormData.promotionRuleCountrys.forEach(item2 => {
                    if (item.countryCode == item2.countryCode  &&
                        this.getPromotionPrice(item) > this.getPromotionPrice(item2)) {
                            compareResult = true
                    }
                })
            })
            return compareResult
        },
        //获取促销价格
        getPromotionPrice(promotion) {
            if (promotion.promotionType == 'Price') {
                return promotion.promotionValue ? Number(promotion.promotionValue) : promotion.promotionValue;
            } else {
                return promotion.discount ? Number(promotion.discount) : promotion.discount
            }
        },
        //是否显示促销
        isShowGoodsPromotion,
        //获取促销价格
        getPromotionGoodsPrice,
    },
    //要早于组件初始化
    created() {
        this.initData()
    },
};
</script>
<style scoped>
.input-width {
    width: 70%;
}

.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}

.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.prpa {
    display: flex;
    justify-content: center;
    align-items: center;
}

.prpa-sub {
    border: 1px solid #000000;
    width: 80%;
}

.content1 {
    width: auto;
    height: 100%;
    overflow-y: scroll;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.content-sub-left {
    cursor: pointer;
    position: relative;
    background-color: #ececec;
    width: 40%;
}

.content-sub-right {
    width: 60%;
}

.content-sub-item {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    padding: 10px 0px 10px 10px;
}

.active::before {
    content: '';
    position: absolute;
    border-left: 4px solid #55aaff;
    height: 30px;
    left: 0px;
}

.active {
    font-weight: 900;
    font-style: normal;
    font-size: 20px;
    background-color: #46a8a0;
    color: white;
}

.content2 {
    width: auto;
    height: 100%;
}

.content2-sub {
    font-size: 15px;
    color: #464646;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/deep/ .seRadio .el-radio__label {
    display: none;
}

.must .el-form-item__label:before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
}

</style>