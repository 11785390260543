<template>
	<div>
        <el-dialog
            :title="$t('common.Prompt')"
            :visible.sync="dialogTimeOverlayVisible"
            width="40%"
            append-to-body
            :before-close="handleTimeOverlayClose"
        >
            <div v-if="dialogTimeOverlayVisible">
                <div style="width: 100%; display: flex; justify-content: center; align-items: center">
                    <div style="width: 80%">
                        <div v-for="(item, index) in checkTimeOverlay" :key="index" style="margin: 10px 10px">
                            {{handleTipTranslation(item)}}
                        </div>
                    </div>
                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="handleTimeOverlayClose">{{ $t('common.Confirm') }}</el-button>
                </span>
            </div>
        </el-dialog>
	</div>
</template>

<script>
	import X2JS from 'x2js'; //解析xml
	export default {
		props:{
            checkTimeOverlay: {
				type: Array,
				default: () => {
					return [];
				}
			},
            dialogTimeOverlayVisible:{
				type: Boolean,
				default: false
			},
		},
		data(){
			return{
			}
		},
		mounted() {
		},
	    methods: {
            handleTimeOverlayClose() {
                this.$emit('dialogVisibleEvent', false)
            },
            handleTipTranslation(item){
                let promotionTypeStr = item.userType == 'NEW' ? this.$i18n.t('common.NewUser') : this.$i18n.t('common.RegularUser')
                let countryStr = this.$dict.getDictValue('countrys', item.countryCode)

                let finalStr = this.$i18n.t('common.SKUBIDActivities',
                    {
                        sku : item.sku,
                        country: countryStr,
                        activity: promotionTypeStr
                    }) + "," + this.$i18n.t('common.PleaseProceedEdit')

                return finalStr
            }
	    }
	}
</script>

<style scoped>
.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
</style>